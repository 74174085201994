
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionSaldo } from "@/feature-flags/flags-permission-saldo";
import { AccountController } from "../../controllers/AccountController";
@Options({
  components: {},
})
export default class Balance extends Vue {
  get currentRoute() {
    return this.$route.name;
  }
  redirectToNearestTab() {
    if (this.currentRoute === "balance-layout") {
      this.changeTabs(this.availableTabs[0].name);
    }
  }
  mounted() {
    this.redirectToNearestTab();
  }
  updated() {
    this.redirectToNearestTab();
  }
  // Tabs
  get tabs() {
    const tab = [
      {
        name: "balance-mutation",
        title: "Saldo",
        permission: FlagsPermissionSaldo.permission_saldo_balance_mutation,
      },
      {
        name: "balance-mutation-nett",
        title: "Saldo Nett",
        permission: FlagsPermissionSaldo.permission_saldo_balance_mutation_nett,
      },
      {
        name: "hold-balance-history",
        title: "Saldo Tertahan",
        permission: FlagsPermissionSaldo.permission_saldo_hold_balance_history,
      },
    ];
    const foreignTab = [
      {
        name: "balance-mutation-nett",
        title: "Net Balance",
        permission: FlagsPermissionSaldo.permission_saldo_balance_mutation_nett,
      },
    ];
    return this.accountIsForeign ? foreignTab : tab;
  }
  get availableTabs() {
    return this.tabs.filter((tab) => tab.permission.isEnabled());
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value,
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
